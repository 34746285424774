<script setup lang="ts">
import { Bell } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import type { NotificationItem } from "@/shared/types";
import { MessageType, ChatType } from "@/shared/enums";
const UseNotifications = useNotificationsStore();
const { lastUnread } = storeToRefs(UseNotifications);
const { notificationsConnect, sendIsRead } = UseNotifications;
const { $subscribeUser } = useNuxtApp();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const localePath = useLocalePath();

const UseLocalStore = useLocalStore();
const { notificationSettings } = storeToRefs(UseLocalStore);
onMounted(async () => {
  try {
    await notificationsConnect();
  } catch (e: any) {
    ElMessage.error(
      e?.data?.data?.message || t("c-notifications-indexclient-connectError")
    );
  }
});
const dropdownRef = ref();
const readedMessages = ref<string[]>([]);
const markAsRead = (noty: NotificationItem) => {
  if (
    !noty.hash ||
    noty.hash === "" ||
    readedMessages.value.includes(noty.hash)
  )
    return;
  readedMessages.value.push(noty.hash);
};
const unredCount = computed(() => {
  const count = lastUnread.value.length - readedMessages.value.length;
  return count > 0 ? count : 0;
});
const onChange = (visible: boolean) => {
  if (visible || !readedMessages.value.length) {
    lastUnread.value.forEach((noty) => markAsRead(noty));
    return;
  }
  sendIsRead(readedMessages.value);
  readedMessages.value = [];
};

const isInsideOrder = (notification: any) => {
  return +route.params?.orderId === +(notification.baseMessage.orderId || 0);
};
const isInsdeRequest = (notification: any) => {
  return +route.params?.requestId === +(notification.baseMessage.orderId || 0);
};
const lastUnreadWatcher = watch(
  () => lastUnread.value.length,
  () => {
    if (!lastUnread.value.length) {
      return;
    }
    const notification = lastUnread.value?.[0];
    if (!notification) return;
    if (
      (!route.params.orderId && !route.params.requestId) ||
      (!isInsideOrder(notification) && !isInsdeRequest(notification))
    ) {
      createMessageNotification(notification);
      createSystemNotification(notification);
    } else if (isInsideOrder(notification) || isInsdeRequest(notification)) {
      sendIsRead([notification.baseMessage.hash]);
      if (document.hidden) {
        createSystemNotification(notification);
      }
    }
  }
);
const paramsOrderIdWatcher = watch(
  () => route.params.orderId,
  () => {
    if (!route.params.orderId) return;
    lastUnread.value.forEach((notification) => {
      if (
        notification.baseMessage.chatType === ChatType.orderChat &&
        +route.params.orderId === +(notification.baseMessage.orderId || 0)
      ) {
        readedMessages.value.push(notification.baseMessage.hash);
      }
    });
    sendIsRead(readedMessages.value);
    readedMessages.value = [];
  },
  { immediate: true }
);
const paramsRequestIdWatcher = watch(
  () => route.params.requestId,
  () => {
    if (!route.params.requestId) return;
    lastUnread.value.forEach((notification) => {
      if (
        notification.baseMessage.chatType === ChatType.exchangeChat &&
        +route.params.requestId === +(notification.baseMessage.orderId || 0)
      ) {
        readedMessages.value.push(notification.baseMessage.hash);
      }
    });
    sendIsRead(readedMessages.value);
    readedMessages.value = [];
  },
  { immediate: true }
);

const shownNotifications = ref<ReturnType<typeof ElNotification>[]>([]);
const hideAllPressed = ref(false);
const hideAll = () => {
  hideAllPressed.value = true;
  shownNotifications.value.forEach((noty) => noty.close());
};
const showHideAllButton = computed(() => {
  return lastUnread.value.length > 2 && !hideAllPressed.value;
});
const createMessageNotification = (notification: NotificationItem) => {
  if (
    notification.baseMessage.type === MessageType.UserMessage &&
    !notificationSettings.value.web.chat
  ) {
    return;
  }
  if (
    notification.baseMessage.type !== MessageType.UserMessage &&
    !notificationSettings.value.web.order
  ) {
    return;
  }

  const getOrderUrl = (msg: any = {}) => {
    if (msg.chatType === ChatType.exchangeChat) {
      if (msg.moderator) {
        return `/administration/exchange/requests/${msg.orderId}`;
      } else {
        return `/exchange/requests/${msg.orderId}`;
      }
    } else if (msg.chatType === ChatType.orderChat) {
      if (msg.moderator) {
        return `/administration/disputes/${msg.orderId}`;
      } else {
        return `/trade/orders/${msg.orderId}`;
      }
    }
  };
  const myNoty = ElNotification({
    offset: 100,
    title: notification.title,
    message: notification.text,
    duration: 60000,
    showClose: true,
    customClass: "cursor-pointer pointer",
    onClick: async () => {
      markAsRead(notification);
      hideAll();
      await nextTick();
      const URL = getOrderUrl(notification.baseMessage);
      URL && router.push(localePath(URL));
    },
  });
  shownNotifications.value.push(myNoty);
};
const createSystemNotification = (notification: NotificationItem) => {
  if (
    notification.baseMessage.type === MessageType.UserMessage &&
    !notificationSettings.value.system.chat
  ) {
    return;
  }
  if (
    notification.baseMessage.type !== MessageType.UserMessage &&
    !notificationSettings.value.system.order
  ) {
    return;
  }

  ($subscribeUser as any)?.({
    title: notification.title,
    text: notification.text,
    url: `/trade/orders/${notification.baseMessage?.orderId}`,
  });
};
onBeforeUnmount(() => {
  lastUnreadWatcher();
  paramsOrderIdWatcher();
  paramsRequestIdWatcher();
});
</script>

<template>
  <div>
    <el-button
      plain
      type="primary"
      class="hide-all-button"
      v-if="showHideAllButton"
      @click="hideAll"
      round
    >
      {{ t("c-notifications-indexclient-hideAllMessages") }}
    </el-button>
    <el-dropdown trigger="click" ref="dropdownRef" @visible-change="onChange">
      <el-button text round>
        <el-badge
          :value="unredCount"
          :hidden="!unredCount"
          class="item"
          type="primary"
        >
          <el-icon><Bell /></el-icon>
        </el-badge>
      </el-button>
      <template #dropdown>
        <div class="p-2 whitespace-nowrap">
          <NuxtLink
            @click="dropdownRef.handleClose()"
            :to="localePath('/user/settings')"
          >
            {{ t("c-notifications-indexclient-goToSettings") }}
          </NuxtLink>
        </div>
        <div>
          <el-scrollbar max-height="250px">
            <div class="p-2">
              <div class="opacity-55 p-1" v-if="!lastUnread.length">
                {{ t("c-notifications-indexclient-nothingNew") }}
              </div>
              <div
                v-for="(notification, i) in lastUnread"
                :key="notification.hash + i"
              >
                <el-badge
                  value="new"
                  class="notification-item-badge"
                  type="primary"
                  :hidden="readedMessages.includes(notification.hash)"
                >
                  <NotificationsItem
                    class="notification-item"
                    v-bind="notification"
                    @click="dropdownRef.handleClose()"
                  />
                </el-badge>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div>
          <nuxt-link
            to="/user/notifications"
            @click="dropdownRef.handleClose()"
          >
            <el-button type="primary" link plain class="w-full mb-1" round>{{
              !lastUnread.length
                ? t("c-notifications-indexclient-viewOlder")
                : t("c-notifications-indexclient-viewAll")
            }}</el-button>
          </nuxt-link>
        </div>
      </template>
    </el-dropdown>
  </div>
</template>

<style scoped lang="scss">
.hide-all-button {
  position: fixed;
  right: 20px;
  top: 70px;
  z-index: 1000;
}
.notification-item-badge {
  :deep(.el-badge__content) {
    transform: translateY(-35%) translateX(40%);
  }
}
</style>
